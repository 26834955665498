import React, { useEffect, useState } from 'react';
import { Alert, Box, Snackbar, TextField } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';
import { LoadingButton } from '@material-ui/lab';

const apiUrl = process.env.REACT_APP_API_URL || '';
const apiKey = process.env.REACT_APP_API_KEY || '';

const FileUpload = () => {
	const [anomalies, setAnomalies] = useState();
	const [filesToUpload, setFilesToUpload] = useState();
	const [issue, setIssue] = useState(null);
	const [disabled, setDisabled] = useState(true);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState({
		open: false,
		type: 'success',
		message: 'Les données ont bien été importées',
	});

	const displayAlert = (type, message) => {
		setLoading(false);
		setDisabled(false);
		let alertCpy = {
			open: true,
			message: message,
			type: type,
		};
		setAlert(alertCpy);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		let open = false;
		setAlert({ ...open });
	};

	useEffect(() => {
		fetch(apiUrl + 'abnormality?all=true', {
			headers: {
				'x-api-key': apiKey,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				data.map((issue) => {
					issue.label = issue.title;
					return issue;
				});
				setAnomalies(data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	if (anomalies === undefined) {
		return <>Chargement...</>;
	}

	const handleAnomalies = (event, newValue) => {
		setIssue(newValue.id);
		if (filesToUpload) {
			setDisabled(false);
		}
	};

	const handleFilesToUpload = (e) => {
		setFilesToUpload(e.target.files);
		if (issue) {
			setDisabled(false);
		}
	};

	const handleSubmit = (e) => {
		let counter = 0;
		e.preventDefault();
		setLoading(true);
		const names = [];
		for (let index = 0; index < filesToUpload.length; index++) {
			fetch(
				apiUrl +
					'presigned-url?fileName=' +
					filesToUpload[index].name +
					'&response-content-disposition=inline&response-content-type=application/pdf',
				{
					headers: {
						'x-api-key': apiKey,
					},
				}
			)
				.then((response) => response.json())
				.then((data) => {
					const url = data.fileUploadURL;
					const fileName = data.fileName;
					fetch(url, {
						method: 'PUT',
						body: filesToUpload[index],
						headers: {
							'Content-Type': 'application/pdf',
							'x-api-key': apiKey,
						},
					})
						.then((res) => {
							names.push(fileName);
							if (counter === filesToUpload.length - 1) {
								fetch(apiUrl + 'save-file-name', {
									method: 'post',
									body: JSON.stringify({
										fileName: names,
										issue_id: issue,
									}),
									headers: { 'x-api-key': apiKey },
								})
									.then((response) => response.json())
									.then((data) => {
										displayAlert(
											'success',
											'Les données ont pu être importées'
										);
									})
									.catch((err) => {
										displayAlert(
											'error',
											"Les données n'ont pas pu être importées"
										);
									});
							}
							setLoading(false);
							setDisabled(false);
							counter++;
						})
						.catch((err) => {
							displayAlert(
								'error',
								"Les données n'ont pas pu être importées"
							);
							setDisabled(false);
							setLoading(false);
						});
				});
		}
	};

	return (
		<Box
			sx={{
				width: '100%',
				height: '100vh',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<div
				className='glassEffect'
				style={{ padding: '50px', color: 'white', backgroundColor: '' }}
			>
				<div style={{ textAlign: 'center' }}>
					<form onSubmit={handleSubmit}>
						<Autocomplete
							disablePortal
							options={anomalies}
							id='select'
							sx={{ width: '100%' }}
							renderInput={(params) => (
								<TextField {...params} label='Anomalies' />
							)}
							onChange={handleAnomalies}
						/>
						<div style={{ width: '100%' }}>
							<input
								multiple
								type='file'
								className='form-control-file'
								id='fileUpload'
								style={{ margin: '2em 0px' }}
								onChange={handleFilesToUpload}
							/>
						</div>
						<LoadingButton
							type='submit'
							loading={loading}
							variant='contained'
							disabled={disabled}
							sx={{ my: 1, height: '56px', width: '100%' }}
						>
							Téléverser les fichiers
						</LoadingButton>
					</form>
				</div>
			</div>
			<Snackbar
				open={alert.open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					severity={alert.type}
					test={alert}
					sx={{ width: '100%' }}
				>
					{alert.message}
				</Alert>
			</Snackbar>
		</Box>
	);
};

export default FileUpload;
