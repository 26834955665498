import * as sha512 from 'js-sha512';

const X_API_URL = process.env.REACT_APP_API_URL || "";
const X_API_KEY = process.env.REACT_APP_API_KEY || "";
export default class AuthenticationService {

	static isAuthenticated = false;
	static user_email;
	static token;
	static role = 'user';

	static async login(email, password) {
		var isAuthenticated = false;
		var pswd = sha512.sha512(password);

		var credentials = {
			email: email,
			password: pswd
		}

		this.user_email = email

		await fetch(`${X_API_URL}login`, { headers: { 'Content-Type': 'application/json', 'x-api-key': X_API_KEY }, method:'post', body:  JSON.stringify(credentials)})
		.then(response => response.json())
		.then(res => {
			if(res.message === 'error') {
				throw new Error('error');
			}
			if (res) {
				isAuthenticated = true;
				this.token = res.token;
				this.role = res.role;
			}
		})
		.catch(err => {throw err;});

		localStorage.setItem('token', this.token);
		localStorage.setItem('email', email);
		localStorage.setItem('role', this.role);
		
		return new Promise(resolve => {
			this.isAuthenticated = isAuthenticated;
			resolve(isAuthenticated);
		});
	}

	static async logout() {
		const credentials = {
			email: this.user_email
		}
		var isAuthenticated = false
		fetch(`${X_API_URL}logout`, { headers: { 'Content-Type': 'application/json', 'x-api-key': X_API_KEY }, method:'post',body: JSON.stringify(credentials)})
		.then((res) => res.json())
		.then((res) => {
			localStorage.removeItem('token');
			localStorage.removeItem('email');
			localStorage.removeItem('role');
			this.user_email = '';
			this.token = '';
			this.role = 'user';
			window.location.reload();
		})
		.catch(e => {
			console.log(e);
		});

		return new Promise(resolve => {
			this.isAuthenticated = isAuthenticated;
			resolve(isAuthenticated);
		});
	}
}