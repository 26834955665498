import React, { useEffect, useState } from 'react';
import Abnormality from './Abnormality';
import {
	Grid,
	Box,
	ToggleButtonGroup,
	ToggleButton,
	TextField,
	Select,
	MenuItem,
	Switch,
	FormControlLabel,
	InputLabel,
	FormControl,
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import accent from 'remove-accents';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const apiUrl = process.env.REACT_APP_API_URL || '';
const apiKey = process.env.REACT_APP_API_KEY || '';

const Anomalies = () => {
	// all anomalies
	const [anomalies, setAnomalies] = useState();
	// all anomalies to display
	const [displayAnomalies, setDisplayAnomalies] = useState();
	// filter start date
	const [startDate, setStartDate] = useState(null);
	// filter end date
	const [endDate, setEndDate] = useState(null);
	// filter search text
	const [search, setSearch] = useState('');
	// sort type
	const [sort, setSort] = useState('create');
	// filter important anomalies
	const [important, setImportant] = useState(false);
	// used categories
	// eslint-disable-next-line
	const [categories, setCategories] = useState([
		{ name: 'MDT' },
		{ name: 'Paramétrage Local' },
		{ name: 'Paramétrage National' },
		{ name: 'Bug' },
		{ name: 'Conception' },
	]);
	// selected categories
	const [categoriesSelect, setCategoriesSelect] = useState(['all']);

	// handle categories filter
	const handleCategories = (event, newCategories) => {
		if (
			newCategories.length === 0 ||
			(categories.length === newCategories.length &&
				!newCategories.includes('all')) ||
			(!categoriesSelect.includes('all') && newCategories.includes('all'))
		)
			newCategories = ['all'];
		if (newCategories.length > 1) {
			newCategories = newCategories.filter((el) => {
				return el !== 'all';
			});
		}
		setCategoriesSelect(newCategories);
		filter(search, newCategories, startDate, endDate, important);
	};

	// handle sort ability
	const handleSort = (event) => {
		setSort(event.target.value);
		sortFunction(event.target.value);
	};

	// remove accents and lower text
	const prepareText = (text) => {
		return accent.remove(text.toLowerCase());
	};

	const filter = (value, categoriesFilter, start, end, important) => {
		const newAnomalies = anomalies.filter((issue) => {
			// remove (hh:mi:ss) time from date
			if (start != null) {
				start = new Date(start);
			}
			if (end != null) {
				end = new Date(end);
			}
			var issueDate = new Date(issue.date);
			if (
				!categoriesFilter.includes('all') &&
				!categoriesFilter.includes(issue.category)
			) {
				return false;
			}
			if (
				value !== '' &&
				!prepareText(issue.title).includes(prepareText(value)) &&
				!prepareText(issue.returnToGive).includes(prepareText(value))
			) {
				return false;
			}
			if (
				start !== null &&
				end !== null &&
				(issueDate.getTime() < start.getTime() ||
					issueDate.getTime() > end.getTime())
			) {
				return false;
			}
			if (important && !issue.important) {
				return false;
			}
			return true;
		});
		setDisplayAnomalies(newAnomalies);
	};

	const sortFunction = (sortType) => {
		const newAnomalies = [...displayAnomalies].sort((a, b) => {
			switch (sortType) {
				case 'milestone':
					var testA = a.milestone,
						testB = b.milestone;
					if (testA === undefined) {
						testA = '9/1/1970';
					}
					if (testB === undefined) {
						testB = '9/1/1970';
					}
					if (new Date(testA).getTime() > new Date(testB).getTime()) {
						return -1;
					}
					if (new Date(testA).getTime() > new Date(testB).getTime()) {
						return 1;
					}
					return 0;
				case 'create':
					if (
						new Date(a.date).getTime() > new Date(b.date).getTime()
					) {
						return -1;
					}
					if (
						new Date(a.date).getTime() < new Date(b.date).getTime()
					) {
						return 1;
					}
					return 0;
				case 'letters':
					if (
						a.title.trim().toLowerCase() <
						b.title.trim().toLowerCase()
					) {
						return -1;
					}
					if (
						a.title.trim().toLowerCase() >
						b.title.trim().toLowerCase()
					) {
						return 1;
					}
					return 0;
				case 'fnes':
					if (a.counter > b.counter) {
						return -1;
					}
					if (a.counter < b.counter) {
						return 1;
					}
					return 0;
				default:
					return 0;
			}
		});
		setDisplayAnomalies(newAnomalies);
	};

	const handleStartDate = (date) => {
		if (endDate == null) {
			setEndDate(new Date(date));
		}
		setStartDate(date);
		filter(
			search,
			categoriesSelect,
			date,
			endDate || new Date(date),
			important
		);
	};

	const handleEndDate = (date) => {
		if (startDate == null) {
			setStartDate(new Date(date).setDate(new Date(date).getDate() - 1));
		}
		setEndDate(date);
		filter(
			search,
			categoriesSelect,
			startDate || new Date(date).setDate(new Date(date).getDate() - 1),
			date,
			important
		);
	};

	const handleSearch = (event) => {
		setSearch(event.target.value);
		filter(
			event.target.value,
			categoriesSelect,
			startDate,
			endDate,
			important
		);
	};

	const handleImportant = () => {
		var newImportant = !important;
		setImportant(newImportant);
		filter(search, categoriesSelect, startDate, endDate, newImportant);
	};

	useEffect(() => {
		fetch(apiUrl + 'abnormality', {
			headers: {
				'x-api-key': apiKey,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				var anomalies = data.sort((a, b) => {
					if (
						new Date(a.date).getTime() > new Date(b.date).getTime()
					) {
						return -1;
					}
					if (
						new Date(a.date).getTime() < new Date(b.date).getTime()
					) {
						return 1;
					}
					return 0;
				});
				setAnomalies(anomalies);
				setDisplayAnomalies(anomalies);
			});
	}, []);

	// wait for var to load
	if (
		anomalies === undefined ||
		displayAnomalies === undefined ||
		categories === undefined
	) {
		return <>Chargement...</>;
	}

	const AnomaliesDisplay = ({ anomalies }) => {
		var res = anomalies.map((abnormality, i) => {
			return <Abnormality key={i} value={abnormality} />;
		});
		return res;
	};

	const panel = (
		<Box sx={{ ml: 2 }}>
			<Grid container spacing={2} align='left'>
				<Grid item xs={12} md={6}>
					<TextField
						id='search'
						label='Recherche'
						variant='outlined'
						onChange={handleSearch}
						sx={{ m: 1, width: '100%' }}
					/>
				</Grid>

				<Grid item sm={6} md={2} sx={{ m: 'auto' }}>
					<FormControlLabel
						control={
							<Switch
								checked={important}
								onChange={handleImportant}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						}
						label='Points importants'
					/>
				</Grid>
				<Grid item sm={6} md={2} sx={{ m: 'auto' }}>
					<FormControl>
						<InputLabel id='sort-label'>Ordoner par</InputLabel>
						<Select
							labelId='sort-label'
							id='sort-select'
							value={sort}
							onChange={handleSort}
							label='Ordoner par'
						>
							<MenuItem value={'create'}>
								Date de création
							</MenuItem>
							<MenuItem value={'milestone'}>
								Date de résolution
							</MenuItem>
							<MenuItem value={'letters'}>
								Ordre alphabétique
							</MenuItem>
							<MenuItem value={'fnes'}>
								Nombre de FNEs associées
							</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Accordion sx={{ width: '100%', mb: 5, mt: 2 }}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='filters-content'
						id='filters-header'
					>
						<Typography>Plus de filtres</Typography>
					</AccordionSummary>
					<AccordionDetails sx={{}}>
						<Grid container>
							<Grid
								item
								xs={12}
								md={6}
								sx={{ overflowX: 'auto' }}
							>
								<ToggleButtonGroup
									value={categoriesSelect}
									onChange={handleCategories}
									aria-label='categories'
									sx={{ m: 1 }}
								>
									<ToggleButton
										value='all'
										aria-label='all categories'
									>
										TOUS
									</ToggleButton>
									{categories.map((category, i) => (
										<ToggleButton
											key={i}
											value={category.name}
											aria-label={
												category.name + '-category'
											}
										>
											{category.name}
										</ToggleButton>
									))}
								</ToggleButtonGroup>
							</Grid>
							<Grid
								item
								xs={12}
								md={6}
								sx={{ lineHeight: '56px', margin: 'auto' }}
							>
								<Grid container>
									<Grid item xs={12} md={2}>
										Entre le
									</Grid>
									<Grid item xs={12} md={4}>
										<LocalizationProvider
											dateAdapter={AdapterMoment}
										>
											<DatePicker
												value={startDate}
												inputFormat='DD/MM/YYYY'
												onChange={handleStartDate}
												renderInput={(params) => (
													<TextField
														{...params}
														sx={{ mx: 2 }}
													/>
												)}
											/>
										</LocalizationProvider>
									</Grid>
									<Grid item xs={12} md={2}>
										et le
									</Grid>
									<Grid item xs={12} md={4}>
										<LocalizationProvider
											dateAdapter={AdapterMoment}
										>
											<DatePicker
												value={endDate}
												inputFormat='DD/MM/YYYY'
												onChange={handleEndDate}
												renderInput={(params) => (
													<TextField
														{...params}
														sx={{ ml: 2 }}
													/>
												)}
											/>
										</LocalizationProvider>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			</Grid>
		</Box>
	);

	return (
		<>
			{panel}
			<AnomaliesDisplay anomalies={displayAnomalies} />
		</>
	);
};

export default Anomalies;
