import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import AuthenticationService from '../Authentification/AuthenticationService';
import StoreData from './StoreData';
import ManageAnomalies from './ManageAnomalies';
import FileUpload from './FileUpload';
import { useNavigate } from 'react-router-dom';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };


export default function BasicTabs() {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleLogout = (event) => {
      AuthenticationService.logout();
    };  

    const handleAdmin = (event) => {
    
        navigate('../')
    };
    
  
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable">
            <Tab label="Manager les Anomalies" {...a11yProps(0)} />
            <Tab label="Déposer un fichier" {...a11yProps(1)} />
            <Tab label="Rentrer une Anomalie" {...a11yProps(2)} />
            <Box sx={{width:'100%', mx:1,display:'flex',justifyContent:"flex-end"}}>
            <Button type='submit' onClick={handleAdmin}>Retour à l'accueil</Button>
              <Button type='submit' onClick={handleLogout}>Se déconnecter</Button>
            </Box>
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
            <ManageAnomalies/>
        </TabPanel>

        <TabPanel value={value} index={1}>
            <StoreData/>
        </TabPanel>
        
        <TabPanel value={value} index={2}>
            <FileUpload/>
        </TabPanel>
      </Box>
    );
  }
