import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from './Component/Home';
import StoreData from './Component/Backoffice/StoreData';
import Error404 from './Component/Error/Error404';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Login from './Component/Authentification/Login'
import RequireAuth from './Component/Authentification/RequireAuth'
import FileUpload from './Component/Backoffice/FileUpload';
import ManageAnomalies from './Component/Backoffice/ManageAnomalies';
import Gestion from './Component/Backoffice/Gestion';
function App() {

  return (
    <ThemeProvider theme={createTheme({
      palette: {
        mode:'dark',
      }})}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<RequireAuth role={"user"}>
            <Home/>
          </RequireAuth>}/>
          <Route path="/store" element={<RequireAuth role={"admin"}>
            <StoreData/>
          </RequireAuth>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/upload" element={<RequireAuth role={"admin"}>
            <FileUpload/>
          </RequireAuth>}/>
          <Route path="/manage" element={<RequireAuth role={"admin"}>
            <ManageAnomalies/>
          </RequireAuth>}/>
          <Route path="*" element={<Error404/>} />
          <Route path="/gestion" element={<RequireAuth role={"admin"}>
            <Gestion/>
          </RequireAuth>}/>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
