import { Grid, Grow, Box, Typography, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Globe from 'react-globe.gl';
import * as THREE from 'three';
import data from '../../data/routes.json'

const TEXTURE = new THREE.MeshStandardMaterial({
    transparent: true, 
    side: THREE.DoubleSide, 
    alphaTest: 0,
    color: 'rgb(0, 15, 56)',
    alphaMap: new THREE.TextureLoader().load( 'world_flat_dots.jpeg' )
});

const Error = () => {
    const globeEl = useRef();
    const [height,setHeight] = useState(window.innerHeight);
    const [width,setWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setHeight(window.innerHeight)
        setWidth(window.innerWidth)
    };
    window.addEventListener('resize',handleResize);


    useEffect(() => {
        const globe = globeEl.current;
        globe.controls().autoRotate = true;
        globe.controls().autoRotateSpeed = -0.35;
        globe.controls().enableZoom = false;
        globe.controls().minPolarAngle = Math.PI/3;
        globe.controls().maxPolarAngle = Math.PI/3;
    },[]);

    return <Box style={{backgroundColor:'rgba(1, 20, 77,0.9)'}}>
         <Grow
                in={true}
                style={{ transform: '0 0 0' }}{...{ timeout: 800 } }>
            <Box className='error_modal' sx={{
                textAlign:'center',
                zIndex:100, 
                borderRadius: 1,
                boxShadow:3,
                width:'100%',
                padding:'2% 6%',
                backgroundColor:'#232323',
                position:'fixed',
                top: '90%',
                left: '50%',
                transform: 'translate(-50%, -90%)!important'}}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h2">
                            Oups ! 
                        </Typography>
                        <Typography sx={{ my: 2 }}>
                            Il semblerait que vous soyez perdu
                        </Typography>
                        <Button variant="contained" href="/">
                            Revenir à la maison
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Grow>
        

        <Globe
            ref={globeEl}
            width={width}
            height={height}
            globeImageUrl="world_flat_dots.png"
            backgroundColor="rgba(0,0,0,0)"
            showAtmosphere={true}
            globeMaterial={TEXTURE}
            arcsData={data}
            arcColor={() => 'cyan'}
            arcAltitudeAutoScale={0.25}
            arcDashInitialGap={() => Math.random()*2}
            arcDashGap={() => 1}
            arcDashAnimateTime={() => Math.random() * 4000 + 5000}
        />

    </Box> 
}

export default Error;
