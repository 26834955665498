import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Grid,
	Link,
	Button,
	Modal,
} from '@mui/material';
import { Box } from '@mui/material';
import { useState } from 'react';
const Abnormality = (props) => {
	// to know if the attachments modal is open or not
	const [open, setOpen] = useState(false);
	// to know if the accordion details are open or not
	const [show, setShow] = useState(false);
	// the value of the abnormality
	const { value } = props;
	// preparing attachment links
	const Attachments = () => {
		if (value.links) {
			const links = [];
			value.links.forEach((item) => {
				if (item.substr(item.length - 5) === '.pptx') {
					links.push(
						<div key={item}>
							<Link
								target='_blank'
								href={
									'https://view.officeapps.live.com/op/view.aspx?src=https://mach4f.fr/' +
									item
								}
							>
								{item.split('-')[1]}
							</Link>
						</div>
					);
				} else {
					links.push(
						<div key={item}>
							<Link
								target='_blank'
								href={'https://mach4f.fr/' + item}
							>
								{item.split('-')[1]}
							</Link>
						</div>
					);
				}
			});
			return <div style={{ margin: '1em 0' }}>{links}</div>;
		}
		return null;
	};

	// open or close details of accordion
	const handleExpand = (e) => {
		if (e.target.type === 'button') {
			setOpen(true);
		} else {
			setShow(!show);
		}
	};
	// return an abnormality with attachment modal if exist
	return (
		<Accordion
			sx={{ my: 2 }}
			expanded={show}
			style={value.important ? { borderLeft: 'solid 2px #8fc9f9' } : {}}
		>
			<AccordionSummary
				onClick={handleExpand}
				expandIcon={
					value.pj && value.links ? (
						<Button
							onClick={handleExpand}
							sx={{ zIndex: 100000, mr: '0' }}
						>
							{value.links.length} pièce(s) jointe(s)
						</Button>
					) : null
				}
				aria-controls={'accordion-' + value.id + '-content'}
				id={'accordion-' + value.id}
			>
				<Typography component={'span'}>{value.title} </Typography>
			</AccordionSummary>
			<AccordionDetails sx={{ textAlign: 'left' }}>
				<Grid container>
					<Grid item xs={4}>
						<Typography
							variant='body'
							display='block'
							sx={{ color: 'gray' }}
						>
							État : {value.state}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography
							variant='body'
							display='block'
							sx={{ color: 'gray' }}
						>
							Date de création :{' '}
							{new Date(value.date).toLocaleDateString('fr-FR', {
								year: 'numeric',
								month: 'long',
								day: 'numeric',
							})}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography
							variant='body'
							display='block'
							sx={{ color: 'gray' }}
						>
							Catégorie : {value.category}
						</Typography>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={4}>
						<Typography
							variant='body'
							display='block'
							sx={{ color: 'gray' }}
						>
							Nombre de FNEs associées : {value.counter}
						</Typography>
					</Grid>
					{value.milestone !== null &&
					value.milestone !== '' &&
					value.milestone !== 'null' ? (
						<Grid item xs={4}>
							<Typography
								variant='body'
								display='block'
								sx={{ color: 'gray' }}
							>
								Date de résolution :{' '}
								{new Date(value.milestone).toLocaleDateString(
									'fr-FR',
									{
										year: 'numeric',
										month: 'long',
										day: 'numeric',
									}
								)}
							</Typography>
						</Grid>
					) : (
						''
					)}
				</Grid>
				<div style={{ margin: '1em 0' }}>
					<Typography
						component={'div'}
						align='justify'
						style={{ whiteSpace: 'pre-wrap' }}
					>
						{value.returnToGive}
					</Typography>
				</div>
			</AccordionDetails>
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 400,
						bgcolor: 'background.paper',
						border: '2px solid #000',
						boxShadow: 24,
						p: 4,
					}}
				>
					<Typography
						id='modal-modal-title'
						variant='h6'
						component='h2'
					>
						Pièces jointes :
					</Typography>
					<Attachments />
				</Box>
			</Modal>
		</Accordion>
	);
};

export default Abnormality;
