import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import AuthenticationService from "./AuthenticationService";

const X_API_URL = process.env.REACT_APP_API_URL || "";
const X_API_KEY = process.env.REACT_APP_API_KEY || "";

export default function RequireAuth({ role, children }) {
	const [isAuthorized, setIsAuthorized] = useState(null);

	useEffect(() => {
		const email = localStorage.getItem('email') || "";
		const token = localStorage.getItem('token') || "";
		const credentials = {
			email:email,
			token:token
		};
		fetch(`${X_API_URL}loginCheck`, { headers: { 'Content-Type': 'application/json', 'x-api-key': X_API_KEY }, method:'post', body:  JSON.stringify(credentials)})
		.then(response => response.json())
		.then(res => {
			if (res) {
				if(res.message === 'error') {
					setIsAuthorized(false);
				}
				else {
					AuthenticationService.isAuthenticated = true;
					AuthenticationService.user_email = email;
					AuthenticationService.token = token;
					AuthenticationService.role = res.role;
					setIsAuthorized(true);
				}
			}
		})
		.catch(err => {setIsAuthorized(false)});
	}, []);
	if(isAuthorized == null) {
		return '';
	}
	if(isAuthorized && ((AuthenticationService.role === 'admin') || (role === AuthenticationService.role))) {
	  	return children;
	} 
	return <Navigate to="/login"/>
}