import React, { useEffect, useState } from 'react'
import Abnormality from '../Anomalies/Abnormality'

const apiUrl = process.env.REACT_APP_API_URL || "";
const apiKey = process.env.REACT_APP_API_KEY || "";

const Briefing = () => {
    // all anomalies
	const [anomalies, setAnomalies] = useState();
    // all anomalies to display
	const [displayAnomalies, setDisplayAnomalies] = useState();

    useEffect(() => {
        fetch(apiUrl+'abnormality?briefing=true',{headers: {
            'x-api-key': apiKey
        }})
        .then(response => response.json())
        .then(data => {
            setAnomalies(data);
            setDisplayAnomalies(data);
        });
    },[]);

    // wait for var to load
    if (anomalies === undefined || displayAnomalies === undefined) {
        return <>Chargement...</>;
    }

    const AnomaliesDisplay = ({anomalies}) => {
        var res = anomalies.sort((a,b) => {
            if(new Date(a.date).getTime() > new Date(b.date).getTime()) {
                return -1;
            }
            if(new Date(a.date).getTime() < new Date(b.date).getTime()) {
                return 1;
            }
            return 0;
        }).map((abnormality,i) => {
            return <Abnormality key={i} value={abnormality}/>
        })
        return res;
    };

    return (
        <>
            <AnomaliesDisplay anomalies={displayAnomalies}/>
        </>
    );
}

export default Briefing;
