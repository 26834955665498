import { TextField, Grid } from '@mui/material';
import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthenticationService from './AuthenticationService';
import { LoadingButton } from '@material-ui/lab';
import Globe from 'react-globe.gl';
import * as THREE from 'three';
import data from '../../data/routes.json';

const TEXTURE = new THREE.MeshStandardMaterial({
	transparent: true,
	side: THREE.DoubleSide,
	alphaTest: 0,
	color: 'rgb(0, 15, 56)',
	alphaMap: new THREE.TextureLoader().load('world_flat_dots.jpeg'),
});

const GlobeBackGround = () => {
	const navigate = useNavigate();
	const globeEl = useRef();
	const [height, setHeight] = useState(window.innerHeight);
	const [width, setWidth] = useState(window.innerWidth);

	const handleResize = () => {
		setHeight(window.innerHeight);
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		if (AuthenticationService.isAuthenticated) {
			navigate('/');
		}
		const globe = globeEl.current;
		globe.controls().autoRotate = true;
		globe.controls().autoRotateSpeed = -0.35;
		globe.controls().enableZoom = false;
		globe.controls().minPolarAngle = Math.PI / 3;
		globe.controls().maxPolarAngle = Math.PI / 3;
	});

	window.addEventListener('resize', handleResize);

	return (
		<Globe
			ref={globeEl}
			width={width}
			height={height}
			globeImageUrl='world_flat_dots.png'
			backgroundColor='rgba(0,0,0,0)'
			showAtmosphere={true}
			globeMaterial={TEXTURE}
			arcsData={data}
			arcColor={() => 'cyan'}
			arcAltitudeAutoScale={0.25}
			arcDashInitialGap={() => Math.random() * 2}
			arcDashGap={() => 1}
			arcDashAnimateTime={() => Math.random() * 4000 + 5000}
		/>
	);
};

const Login = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (AuthenticationService.isAuthenticated) {
			navigate('/');
		}
	});

	const handleLogin = (e) => {
		e.preventDefault();
		setDisabled(true);
		setLoading(true);
		AuthenticationService.login(email, password)
			.then(() => {
				navigate(state?.path || '/');
			})
			.catch((e) => {
				setError(true);
				setDisabled(false);
				setLoading(false);
			});
	};

	return (
		<Grid container style={{ backgroundColor: 'rgba(1, 20, 77,0.9)' }}>
			<Grid item xs={4} sx={{ textAlign: 'center', margin: 'auto' }}>
				<Box
					sx={{
						zIndex: 100,
						borderRadius: 1,
						boxShadow: 3,
						minWidth: '40vw',
						padding: '2% 6%',
						backgroundColor: '#232323',
						position: 'fixed',
						top: '75%',
						left: '50%',
						transform: 'translate(-50%, -50%)!important',
					}}
					className='login_modal'
				>
					<form onSubmit={handleLogin}>
						<Grid container>
							<Grid item xs={12}>
								<h1 style={{ marginTop: '0', fontWeight: 200 }}>
									Mach4F
								</h1>
								{error ? (
									<h4 style={{ color: 'crimson' }}>
										Vos identifiants sont incorrectes
									</h4>
								) : (
									''
								)}
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									id='email'
									label='Adresse mail'
									variant='outlined'
									onChange={(event) =>
										setEmail(event.target.value)
									}
									sx={{ m: 1, width: '100%' }}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									id='password'
									type={'password'}
									label='Mot de passe'
									variant='outlined'
									onChange={(event) =>
										setPassword(event.target.value)
									}
									sx={{ m: 1, width: '100%' }}
								/>
							</Grid>
							<Grid item xs={12}>
								<LoadingButton
									type='submit'
									loading={loading}
									variant='contained'
									disabled={disabled}
									sx={{ width: '100%', m: 1, height: '56px' }}
								>
									Se connecter
								</LoadingButton>
							</Grid>
						</Grid>
					</form>
				</Box>
			</Grid>
		</Grid>
	);
};

const LoginPage = () => (
	<Grid container style={{ backgroundColor: 'rgba(1, 20, 77,0.9)' }}>
		<Login />
		<GlobeBackGround />
	</Grid>
);

export default LoginPage;
