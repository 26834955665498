import {
	TextField,
	Select,
	MenuItem,
	Checkbox,
	FormControlLabel,
	Grid,
	Container,
	TextareaAutosize,
	Button,
} from '@mui/material';
import { useState } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const apiUrl = process.env.REACT_APP_API_URL || '';
const apiKey = process.env.REACT_APP_API_KEY || '';

const AbnormalityEditForm = (props) => {
	const { row, setOpen, anomalies, setAnomalies, displayAlert } = props;
	const [title, setTitle] = useState(row.title);
	const [state, setState] = useState(row.state);
	const [pj, setPj] = useState(row.pj ? true : false);
	const [category, setCategory] = useState(
		row.category.replace(/\u200B/g, '')
	);
	const [counter, setCounter] = useState(row.counter);
	const [milestone, setMilestone] = useState(row.milestone);
	const [returnToGive, setReturnToGive] = useState(row.returnToGive);
	const [important, setImportant] = useState(row.important);

	const handleClick = (e) => {
		var anomaliesCpy = [...anomalies];
		var editedRow = anomaliesCpy.find((x) => x.id === row.id);
		editedRow.title = title;
		editedRow.state = state;
		editedRow.pj = pj;
		editedRow.category = category;
		editedRow.counter = counter;
		editedRow.milestone = milestone;
		editedRow.returnToGive = returnToGive;
		editedRow.important = important;
		setAnomalies(anomaliesCpy);
		const params = {
			id: row.id,
			title,
			state,
			pj,
			category,
			counter,
			milestone,
			returnToGive,
			important,
		};
		fetch(apiUrl + 'abnormality', {
			headers: {
				'Content-Type': 'application/json',
				'x-api-key': apiKey,
			},
			method: 'put',
			body: JSON.stringify(params),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.message === 'ok') {
					displayAlert(
						'success',
						"L'anomalie a bien été mise à jour"
					);
				} else {
					displayAlert(
						'error',
						"L'anomalie n'a pas pu être mise à jour"
					);
				}
			});
		setOpen(false);
	};

	return (
		<Container sx={{ py: 1 }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						fullWidth
						id='title'
						label='Titre'
						value={title}
						required
						onChange={(e) => setTitle(e.target.value)}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextareaAutosize
						aria-label='retour pour la salle'
						placeholder='Consigne pour la salle'
						style={{ width: '100%' }}
						defaultValue={returnToGive}
						onChange={(e) => setReturnToGive(e.target.value)}
					/>
				</Grid>
				<Grid item xs={4}>
					<Select
						autoWidth
						labelId='state-label'
						id='state'
						value={state}
						required
						label='État'
						onChange={(e) => setState(e.target.value)}
					>
						<MenuItem value={'En cours'}>En cours</MenuItem>
					</Select>
				</Grid>
				<Grid item xs={4} sx={{ textAlign: 'center' }}>
					<FormControlLabel
						control={
							<Checkbox
								checked={pj}
								onChange={(e) => setPj(e.target.checked)}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						}
						label='Possède des pièces jointes ?'
					/>
				</Grid>
				<Grid item xs={4} sx={{ textAlign: 'right' }}>
					<FormControlLabel
						sx={{ mr: 0 }}
						control={
							<Checkbox
								checked={important}
								onChange={(e) => setImportant(e.target.checked)}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						}
						label='Est important ?'
					/>
				</Grid>
				<Grid item xs={4}>
					<Select
						labelId='state-label'
						id='state'
						value={category}
						required
						label='Catégorie'
						onChange={(e) => setCategory(e.target.value)}
					>
						<MenuItem value={''}>Sélectionner</MenuItem>
						<MenuItem value={'MDT'}>MDT</MenuItem>
						<MenuItem value={'Paramétrage Local'}>
							Paramétrage Local
						</MenuItem>
						<MenuItem value={'Paramétrage National'}>
							Paramétrage National
						</MenuItem>
						<MenuItem value={'Bug'}>Bug</MenuItem>
						<MenuItem value={'Conception'}>Conception</MenuItem>
						<MenuItem value={'A discuter'}>À discuter</MenuItem>
					</Select>
				</Grid>
				<Grid item xs={4} sx={{ textAlign: 'center' }}>
					<TextField
						id='counter'
						label='Nombre de FNEs associées'
						type='number'
						value={counter}
						required
						onChange={(e) => setCounter(e.target.value)}
					/>
				</Grid>
				<Grid item xs={4} sx={{ textAlign: 'right' }}>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							value={milestone}
							inputFormat='DD/MM/YYYY'
							onChange={(date) => setMilestone(date)}
							renderInput={(params) => (
								<TextField {...params} sx={{ mx: 2 }} />
							)}
						/>
					</LocalizationProvider>
				</Grid>
			</Grid>
			<Grid
				sx={{ my: 2 }}
				container
				direction='row'
				justifyContent='flex-end'
				alignItems='center'
			>
				<Button variant='contained' size='large' onClick={handleClick}>
					Enregistrer
				</Button>
			</Grid>
		</Container>
	);
};

export default AbnormalityEditForm;
