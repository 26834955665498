import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Anomalies from './Anomalies/Anomalies';
import { Button } from '@mui/material';
import AuthenticationService from './Authentification/AuthenticationService';
import Briefing from './Briefing/Briefing';
import { useNavigate } from 'react-router-dom';





function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {

  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLogout = (event) => {
    AuthenticationService.logout();
  };



  const handleAdmin = (event) => {
    navigate('/gestion')
  };

  const BackofficeButton = () => {
    if(AuthenticationService.role === "admin"){
      return <Button type='submit' onClick={handleAdmin}>Interface administrateur</Button>
    }
    return <></>;
  };



 
  

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs for window selection" variant="scrollable">
          <Tab label="Mach4F" {...a11yProps(0)} />
          <Tab label="Briefing" {...a11yProps(1)} />
          <Box sx={{width:'100%', mx:1,display:'flex',justifyContent:"flex-end"}}>
            <BackofficeButton />
         
            <Button type='submit' onClick={handleLogout}>Se déconnecter</Button>
          </Box>

        

        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Anomalies/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Briefing/>
      </TabPanel>
    </Box>
  );
}
