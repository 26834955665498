import * as React from 'react';
import { Box, Input, Typography, Snackbar, Alert } from '@mui/material/';
import { LoadingButton } from '@material-ui/lab';
import { useState } from 'react';
import * as xlsx from 'xlsx';

const apiUrl = process.env.REACT_APP_API_URL || '';
const apiKey = process.env.REACT_APP_API_KEY || '';

export default function StoreData() {
	const [fileData, setFileData] = useState(null);
	const [disabled, setDisabled] = useState(true);
	const [loading, setLoading] = React.useState(false);

	const [alert, setAlert] = useState({
		open: false,
		type: 'success',
		message: 'Les données ont bien été importées',
	});
	//const headers = ["type","name","category","subins","toDiffuse","state","title","milestone","returnToGive","description","marks","subetu","date","counter","pj",'important','id'];
	const headers = [
		'category',
		'state',
		'title',
		'milestone',
		'returnToGive',
		'date',
		'counter',
		'pj',
		'important',
		'id',
	];

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		let open = false;
		setAlert({ ...open });
	};

	React.useEffect(() => {
		if (fileData !== null) {
			setDisabled(false);
		}
	}, [fileData]);

	const readUploadFile = (e) => {
		e.preventDefault();
		if (e.target.files) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const data = e.target.result;
				const workbook = xlsx.read(data, { type: 'array' });
				const sheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[sheetName];
				const json = xlsx.utils.sheet_to_json(worksheet, {
					header: headers,
					raw: false,
					dateNF: 'yyyy-mm-dd',
				});
				json.shift();
				json.forEach((row) => {
					if (row.date === '') {
						row.date = new Date();
					}
					if (row.pj !== undefined) {
						row.pj = true;
					} else {
						row.pj = false;
					}
					if (row.important === 'VRAI') {
						row.important = true;
					} else {
						row.important = false;
					}
					row.counter = parseInt(row.counter);
				});
				setFileData(json);
			};
			reader.readAsArrayBuffer(e.target.files[0]);
		}
	};

	const displayAlert = (type, message) => {
		setLoading(false);
		setDisabled(false);
		let alertCpy = {
			open: true,
			message: message,
			type: type,
		};
		setAlert(alertCpy);
	};

	const saveData = () => {
		if (fileData !== null) {
			fetch(apiUrl + 'anomalies', {
				headers: {
					'x-api-key': apiKey,
				},
				method: 'post',
				body: JSON.stringify(fileData),
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.message === 'ok') {
						displayAlert(
							'success',
							'Les données ont pu être importées'
						);
					} else {
						displayAlert(
							'error',
							"Les données n'ont pas pu être importées"
						);
					}
				});
		} else {
			displayAlert('error', "Vous n'avez pas renseigné de fichier");
		}
	};

	return (
		<Box
			sx={{
				width: '100%',
				height: '100vh',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<div
				className='glassEffect'
				style={{ padding: '50px', color: 'white', backgroundColor: '' }}
			>
				<Typography
					variant='subtitle1'
					gutterBottom
					component='div'
					sx={{}}
				>
					Vous pouvez déposer un fichier à importer (xlsx, csv, etc..)
				</Typography>
				<div style={{ margin: '25px 0px', textAlign: 'center' }}>
					<label htmlFor='contained-button-file'>
						<Input
							accept='.csv'
							id='contained-button-file'
							type='file'
							onChange={readUploadFile}
							style={{ color: 'white' }}
						/>
					</label>
				</div>
				<div style={{ textAlign: 'center' }}>
					<LoadingButton
						onClick={() => {
							if (
								fileData !== null &&
								window.confirm(
									'Êtes vous sûr de vouloir insérer ces données ?'
								)
							) {
								saveData();
								setLoading(true);
							} else {
								displayAlert(
									'error',
									"Vous n'avez pas renseigné de fichier ou vous avez annulé la requête"
								);
							}
						}}
						loading={loading}
						variant='contained'
						disabled={disabled}
					>
						Ajouter des données
					</LoadingButton>
				</div>
			</div>
			<Snackbar
				open={alert.open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					severity={alert.type}
					test={alert}
					sx={{ width: '100%' }}
				>
					{alert.message}
				</Alert>
			</Snackbar>
		</Box>
	);
}
