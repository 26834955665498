import { DatePicker } from "@mui/lab";
import { TextField, Select, MenuItem, Checkbox, FormControlLabel, Grid, Container, TextareaAutosize, Dialog, DialogTitle, DialogContent, Accordion, AccordionSummary, Typography, AccordionDetails, Snackbar, Alert } from "@mui/material";
import { withStyles } from '@mui/styles';
import React, { useEffect, useState } from "react";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LoadingButton } from '@material-ui/lab';
import { DataGrid, frFR, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AbnormalityEditForm from "./AbnormalityEditForm";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const apiUrl = process.env.REACT_APP_API_URL || "";
const apiKey = process.env.REACT_APP_API_KEY || "";
// mise en forme du form d'add 
// mise en place modal avec données 
// 
// route edit 
// route delete  
// route add 
// visibility return to give => textaera ++ row height

const editRow = (id, field, value) => {
    console.log(id, field, value);
    // todo bouton modif (+route)
}

const ManageAnomalies = () => {
    const StyledDataGrid = withStyles({
      root: {
        "& .MuiDataGrid-renderingZone": {
          maxHeight: "none !important"
        },
        "& .MuiDataGrid-cell": {
          lineHeight: "unset !important",
          maxHeight: "none !important",
          whiteSpace: "normal"
        },
        "& .MuiDataGrid-row": {
          maxHeight: "none !important"
        }
      }
    })(DataGrid);
    
	const [anomalies, setAnomalies] = useState();
    const [title, setTitle]  = useState("");
    const [state, setState]  = useState("En cours");
    const [pj, setPj]  = useState(false);
    const [briefing, setBriefing]  = useState(false);
    const [category, setCategory]  = useState("MDT");
    const [counter, setCounter]  = useState(0);
    const [milestone, setMilestone]  = useState(null);
    const [returnToGive, setReturnToGive]  = useState("");
    const [important, setImportant]  = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [row, setRow] = useState({});
    const rows = [];
    const [alert, setAlert] = useState({
        open:false,
        type:'success',
        message:'Les données ont bien été importées'
    });
      
    const columns = [
        { field: 'title', headerName: 'Titre', sortable: false, flex:1, minWidth: 250, headerAlign: 'center' },
        { field: 'returnToGive', headerName: 'Retour pour la salle', sortable: false, flex:1, minWidth: 250, headerAlign: 'center',
        renderCell: (params) =>  <div>{params.formattedValue}</div>,
        },
        { field: 'milestone', headerName: 'Milestone', type: 'date', sortable: false, flex:1, minWidth: 150, headerAlign: 'center', align: 'center' },
        { field: 'state', headerName: 'État', sortable: false, flex:1, minWidth: 150, headerAlign: 'center', align: 'center' },
        { field: 'category', headerName: 'Catégorie', sortable: false, flex:1, minWidth: 150, headerAlign: 'center', align: 'center' },
        { field: 'briefing', headerName: 'Est un briefing', type: 'boolean', sortable: false, flex:1, minWidth: 150, align: 'center', headerAlign: 'center' },
        { field: 'important', headerName: 'Important', type: 'boolean', sortable: false, flex:1, minWidth: 150, align: 'center', headerAlign: 'center' },
        { field: 'pj', headerName: 'Pièce jointe', type: 'boolean', sortable: false, flex:1, minWidth: 150, align: 'center', headerAlign: 'center' },
        { field: 'counter', headerName: 'FNEs Associées', type: 'number', sortable: false, flex:1, minWidth: 150, align: 'center', headerAlign: 'center' },
        {
            field: "edit",
            headerName: "Éditer",
            sortable: false,
            disableClickEventBubbling: true,
            width:150,
            align: 'center',
            headerAlign: 'center',
            type:'actions',
            getActions: (params) => [
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Éditer"
                  onClick={handleEdit(params.row)}
                />
            ]
        },
        {
            field: "delete",
            headerName: "Supprimer",
            sortable: false,
            disableClickEventBubbling: true,
            width:150,
            align: 'center',
            headerAlign: 'center',
            type:'actions',
            getActions: (params) => [
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Supprimer"
                  onClick={handleDelete(params.row)}
                />
            ]
        }
    ];

    const displayAlert = (type, message) => {
        let alertCpy = {
            open: true,
            message: message,
            type: type
        }
        setAlert(alertCpy);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        let open = false;
        setAlert({...open});
    };

    const handleDelete = (row) => () => {
        const params = {
            id: row.id
        };
        fetch(apiUrl+'abnormality',{headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey},
            method:'delete',
            body:JSON.stringify(params)
        })
        .then(response => response.json())
        .then(data => {
            if(data.message === 'ok') {
                setAnomalies(anomalies.filter(x => x.id === row.id?false:true))
                displayAlert('success','La suppression a bien été effectuée');
            }
            else {
                displayAlert('error','La suppression n\'a pas pu être effectuée');

            }
        });
    };

    const handleEdit = (row) => () => {
        setRow(row);
        setOpen(true);
    }

    useEffect(() => {
        fetch(apiUrl+'abnormality?deleteable=true',{headers: {
            'x-api-key': apiKey
        }})
        .then(response => response.json())
        .then(data => {
            data.map((issue) => {
                issue.label = issue.title;
                return issue;
            });
            setAnomalies(data);
        }).catch(err => {
            console.log(err);
        });
    },[]);

    if (anomalies === undefined) {
        return <>Chargement...</>;
    }
    anomalies.forEach(line => {
        rows.push({
            id: line.id,
            title:line.title,
            returnToGive:line.returnToGive,
            milestone:line.milestone,
            state:line.state,
            category:line.category,
            counter:line.counter,
            important:line.important,
            briefing:line.briefing,
            pj:line.pj,
        });
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const params = {
            title,
            state,
            briefing,
            pj,
            category,
            counter,
            milestone,
            returnToGive,
            important
        };
        fetch(apiUrl+'abnormality',{headers: {
            'x-api-key': apiKey},
            method:'post',
            body:JSON.stringify(params)
        })
        .then(response => response.json())
        .then(data => {
            setLoading(false);
            if(data.message === 'ok') {
                setTitle("");
                setPj(false);
                setBriefing(false);
                setReturnToGive("");
                setCategory("MDT");
                setState("En cours");
                setMilestone(null);
                setCounter(0);
                setImportant(false);
                displayAlert('success','L\'ajout a été effectué');
            }
            else {
                displayAlert('error','L\'ajout n\'a pas pu être fait');
            }
        });
    }
    return (
        <>
            <Container maxWidth={'xl'} sx={{py:5}}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Ajouter une entrée</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="title"
                                        label="Titre"
                                        value={title}
                                        required
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextareaAutosize
                                        minRows={3}
                                        required
                                        aria-label="retour pour la salle"
                                        placeholder="Consigne pour la salle"
                                        value={returnToGive}
                                        style={{ width: '100%' }}
                                        onChange={(e) => setReturnToGive(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Select
                                            autoWidth
                                            labelId="state-label"
                                            id="state"
                                            value={state}
                                            required
                                            label="État"
                                            onChange={(e) => setState(e.target.value)}
                                        >
                                        <MenuItem value={"En cours"}>En cours</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel control={<Checkbox checked={pj} onChange={(e) => setPj(e.target.checked)} inputProps={{ 'aria-label': 'controlled' }}/>} label="Possède des pièces jointes ?" />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel control={<Checkbox checked={briefing} onChange={(e) => setBriefing(e.target.checked)} inputProps={{ 'aria-label': 'controlled' }}/>} label="Est un briefing ?" />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel control={<Checkbox checked={important} onChange={(e) => setImportant(e.target.checked)} inputProps={{ 'aria-label': 'controlled' }}/>} label="Est important ?" />
                                </Grid>
                                <Grid item xs={3}>
                                    <Select
                                        labelId="category-label"
                                        id="category"
                                        value={category}
                                        required
                                        label="Catégorie"
                                        onChange={(e) => setCategory(e.target.value)}
                                    >
                                        <MenuItem value={"MDT"}>MDT</MenuItem>
                                        <MenuItem value={"Paramétrage Local"}>Paramétrage Local</MenuItem>
                                        <MenuItem value={"Paramétrage National"}>Paramétrage National</MenuItem>
                                        <MenuItem value={"Bug"}>Bug</MenuItem>
                                        <MenuItem value={"Conception"}>Conception</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="counter"
                                        label="Nombre de FNEs associées"
                                        type="number"
                                        value={counter}
                                        required
                                        onChange={(e) => setCounter(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                                        <DatePicker
                                            mask='__/__/____'
                                            value={milestone}
                                            label={"Milestone"}
                                            required
                                            onChange={(date) => setMilestone(date)}
                                            renderInput={(params) => <TextField {...params} sx={{mx:2}}/>}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                            </Grid>
                            <Grid container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                sx={{mt:2}}
                            >
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    variant="contained"
                                    sx={{height:'56px'}}
                                >
                                    Sauvegarder
                                </LoadingButton>
                            </Grid>
                        </form>
                    </AccordionDetails>
                </Accordion>
            </Container>
            <Container maxWidth={'xl'}>
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    autoHeight={true}
                    rowHeight={100}
                    isRowSelectable={() => false}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText} 
                    experimentalFeatures={{ newEditingApi: true }}
                    onCellEditStop={(params, event) => {
                        editRow(params.row.id, params.field, event.target.value);
                    }}
                />
            </Container>

            <Dialog 
                open={open} 
                onClose={() => setOpen(false)} 
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle>Édition</DialogTitle>
                <DialogContent>
                    <AbnormalityEditForm row={row} setOpen={setOpen} anomalies={anomalies} setAnomalies={setAnomalies} displayAlert={displayAlert}/>
                </DialogContent>
            </Dialog>
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
                <Alert onClose={handleClose} severity={alert.type}  test={alert} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </>
        
    );
};
export default ManageAnomalies;